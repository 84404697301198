@import "variables";

.soil-scene {
  display: flex;
  justify-content: center;
  position: relative;
  background-color: $soil;

  img.stalk-stem {
    width: 100vw;

    @media (max-width: 480px) {
      width: 250vw;
    }
  }

  .soil-heading-1, .soil-heading-2, .soil-heading-3, .soil-heading-4 {
    position: absolute;
    font-size: 35px;
    font-weight: bold;
    opacity: 1;
    padding: 0 15px;
    width: 30vw;
    z-index: 3;

    @media (min-width: 1440px) {
      font-size: 45px;
      padding: 0 30px 0 90px;
    }

    @media (max-width: 768px) {
      font-size: 24px;
      padding: 0 20px 0 40px;
    }

    @media (max-width: 480px) {
      opacity: 1;
    }
  }

  $soil-heading-padding: 20px;

  .soil-heading-1 {
    top: 120px;
    left: $soil-heading-padding;

    @media (max-width: 480px) {
      position: absolute;
      top: 135vw;
      left: 0;
      text-align: center;
      width: 100%;
      font-size: 30px;
      text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.62);
      padding: 0 20px;
      box-sizing: border-box;
      line-height: 38px;

      .hide {
        display: none;
      }
    }
  }


  .soil-heading-2 {
    top: 20%;
    right: $soil-heading-padding;

    @media (max-width: 480px) {
      position: absolute;
      top: 212vw;
      left: 0;
      text-align: center;
      width: 100%;
      font-size: 30px;
      text-shadow: 0 1px 5px rgba(0, 0, 0, 0.32);
      padding: 0 20px;
      box-sizing: border-box;
      line-height: 38px;

      .hide {
        display: none;
      }
    }
  }

  .soil-heading-3 {
    top: 33%;
    left: $soil-heading-padding;

    @media (max-width: 480px) {
      position: absolute;
      top: 317vw;
      left: 0;
      text-align: center;
      width: 100%;
      font-size: 30px;
      text-shadow: 0 1px 5px rgba(0, 0, 0, 0.32);
      padding: 0 20px;
      box-sizing: border-box;
      line-height: 38px;

      .hide {
        display: none;
      }
    }
  }

  .soil-heading-4 {
    top: 50%;
    right: $soil-heading-padding;

    @media (max-width: 480px) {
      position: absolute;
      top: 465vw;
      left: 0;
      text-align: center;
      width: 100%;
      font-size: 30px;
      text-shadow: 0 1px 5px rgba(0, 0, 0, 0.32);
      padding: 0 20px;
      box-sizing: border-box;
      line-height: 38px;

      .hide {
        display: none;
      }
    }
  }

  .badger-left, .badger-middle, .badger-right {
    z-index: 3;

    img {
      width: 100%;
    }
  }

  #peer-into-text, #to-nurture-text {
    margin-bottom: 40px;
  }

  .underground-badgers {
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    z-index: 6;

    .badger-plowing {
      position: absolute;
      top: $spacingRatio * 0;
      right: 1.4vw;
      width: 12vw;
      z-index: 5;

      @media (max-width: 480px) {
        right: 4.4vw;
        width: 33vw;
      }
    }

    .badger-plowing-right {
      position: absolute;
      top: 96vw;
      right: -18.5vw;
      width: 12vw;
      z-index: 5;

      img {
        width: 100%;
      }

      @media (max-width: 480px) {
        right: -48.6vw;
        width: 33vw;
        top: 230vw;
        z-index: 2;
      }
    }

    .badger-planting {
      position: absolute;
      top: $spacingRatio * 35vw;
      left: 4vw;
      width: 10vw;
      z-index: 5;

      @media (max-width: 480px) {
        top: 16vw;
        left: 11vw;
        width: 29vw;
      }

    }

    .badger-splooting {
      position: absolute;
      top: 60vw;
      left: 6vw;
      width: 7vw;
      z-index: 5;

      @media (max-width: 480px) {
        top: 85vw;
        left: 13vw;
        width: 14vw;
      }
    }

    .badger-planting, .badger-splooting, .badger-plowing {
      img {
        width: 100%;
      }
    }
  }

  .side-trees {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 5;

    // Side
    .tree-manzanitas {
      position: absolute;
      top: 66vw;
      width: 14vw;
      right: 12vw;

      img {
        height: 100%;
      }

      .top-left {
        position: absolute;
        height: 6vw;
        left: -2vw;
        top: -5vw;

        @media (max-width: 480px) {
          position: absolute;
          height: 11vw;
          left: -15vw;
          top: -15vw;
        }
      }

      .top-right {
        position: absolute;
        height: 10vw;
        right: -1vw;
        top: -7vw;

        @media (max-width: 480px) {
          position: absolute;
          height: 15vw;
          right: 15vw;
          top: 4vw;
        }
      }

      .bottom-left {
        position: absolute;
        height: 8vw;
        left: -2vw;
        top: 3vw;

        @media (max-width: 480px) {
          position: absolute;
          height: 17vw;
          left: -17vw;
          top: 21vw;
        }
      }

      .bottom-right {
        position: absolute;
        height: 10vw;
        right: -2vw;
        top: 4vw;

        @media (max-width: 480px) {
          position: absolute;
          height: 13vw;
          right: 17vw;
          top: 41vw;
        }
      }
    }

    .tree-apples {
      position: absolute;
      top: 142vw;
      right: 0;
      width: 20vw;
      display: grid;
      grid-template-columns: 1fr 1fr;

      .apple-tree {
        position: relative;
        height: 10vw;

        img {
          position: absolute;
        }
      }

      .apple1 {
        left: 1vw;
        top: $spacingRatio * 1vw;
      }

      .apple1, .apple5 {
        width: 8vw;
      }

      .apple2, .apple4 {
        width: 10vw;
      }

      .apple3 {
        width: 11vw;
      }

      .apple6 {
        width: 6vw;
        top: $spacingRatio * 1vw;
        right: 3vw;
      }

      @media (max-width: 480px) {
        top: 352vw;
        width: 37vw;

        .apple1 {
          left: -10vw;
          top: 2vw;
        }

        .apple1, .apple5 {
          width: 16vw;
        }

        .apple2, .apple4 {
          width: 20vw;
          left: -8vw;
        }

        .apple4 {
          left: 2vw;
        }

        .apple3 {
          width: 22vw;
          left: -9vw;
        }

        .apple6 {
          width: 19vw;
          top: 9vw;
          left: 9vw;
        }
      }
    }

    .tree-yellows {
      position: absolute;
      top: 186vw;
      left: 4vw;
      width: 20vw;
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media (max-width: 480px) {
        top: 500vw;
        left: 4vw;
        width: 43vw;
        height: 63vw;
      }

      .yellow-tree {
        position: relative;
        height: 10vw;
      }

      img {
        width: 100%;
      }
    }

    .tree-pink {
      position: absolute;
      top: 198vw;
      right: 0;
      width: 17vw;

      @media (max-width: 480px) {
        top: 501vw;
        right: 5vw;
        width: 36vw;
      }
    }
  }

  .shrubbery {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 5;


    // Stalk
    .shrub-1 {
      width: 15vw;
      position: absolute;
      top: $spacingRatio * 53vw;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 5px;
      right: 4vw;

      img {
        width: 100%;
      }
    }

    .shrub-beans {
      position: absolute;
      top: $spacingRatio * 48vw;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      left: 0;
      grid-gap: 5px;

      @media (max-width: 480px) {
        width: 24vw;
      }

      img {
        width: 2vw;

        @media (max-width: 480px) {
          width: 5vw;
        }
      }
    }

    .shrub-pumpkin {
      width: 12vw;
      height: 12vw;
      position: absolute;
      top: 67vw;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      right: -3vw;
      grid-gap: 5px;

      @media (max-width: 480px) {
        width: 30vw;
        height: 35vw;
        top: 92vw;
        right: -10vw;
      }

      img {
        width: 4vw;
        position: absolute;

        @media (max-width: 480px) {
          width: 10vw;
        }
      }
    }

    .shrub-kale {
      position: absolute;
      top: 81vw;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 0;
      left: -5vw;
      width: 14vw;
      height: 15vw;

      @media (max-width: 480px) {
        width: 35vw;
        height: 15vw;
        left: -10vw;
        top: 169vw;
      }

      .kale {
        position: relative;
        height: 100%;
        width: 100%;
      }

      img {
        position: absolute;
        width: 3vw;
      }
    }

    .shrub-corn {
      width: 19vw;
      height: 19vw;
      position: absolute;
      top: 98vw;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 5px;
      right: -4vw;

      @media (max-width: 480px) {
        top: 240vw;
        width: 45vw;
        height: 45vw;
        right: -12vw;
      }

      .corn {
        position: relative;

        img {
          position: absolute;
          width: 5.2vw;

          @media (max-width: 480px) {
            width: 9vw;

          }
        }
      }
    }

    .shrub-pear {
      width: 16vw;
      height: 20vw;
      position: absolute;
      top: 117vw;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 5px;
      left: -6vw;

      .pear {
        position: relative;

        img {
          position: absolute;
          width: 5vw;
        }
      }

      @media (max-width: 480px) {
        width: 27vw;
        height: 28vw;
        position: absolute;
        top: 280vw;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 5px;
        left: -3vw;

        .pear {
          position: relative;

          img {
            position: absolute;
            width: 9vw;
          }
        }
      }


    }

    .shrub-amaranth {
      width: 10vw;
      position: absolute;
      top: 141vw;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 5px;
      left: 3vw;
      height: 21vw;

      .amaranth {
        height: 3.5vw;
        width: 3.5vw;
      }

      @media (max-width: 480px) {
        top: 346vw;
        width: 27vw;
        left: 7vw;
        height: 55vw;

        .amaranth {
          height: 10vw;
          width: 10vw;
        }
      }


    }

    .shrub-collard {
      width: 15vw;
      position: absolute;
      top: 175vw;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 5px;
      right: 0;

      @media (max-width: 480px) {
        width: 30vw;
        top: 413vw;
        right: -5vw;
      }

      img {
        width: 100%;
      }
    }
  }

}



