@import "variables";

.floor-scene {
  position: absolute;
  bottom: 0;

  .tree-fir {
    position: absolute;
    bottom: 71vw;
    width: 32vw;
    z-index: 2;
    left: 12vw;

    @media (max-width: 480px) {
      position: absolute;
      bottom: 164vw;
      width: 77vw;
      z-index: 2;
      left: -40vw;
    }
  }

  .tree-round-1 {
    position: absolute;
    z-index: 0;
    left: 32vw;
    bottom: 85vw;
    width: 12vw;

    @media (max-width: 480px) {
      left: 7vw;
      bottom: 190vw;
      width: 37vw;
    }
  }

  .tree-round-2 {
    width: 13vw;
    position: absolute;
    bottom: 85vw;
    right: 25vw;

    @media (max-width: 480px) {
      display: none;
    }
  }

  .tree-round-3 {
    width: 20vw;
    position: absolute;
    bottom: 77vw;
    right: 12vw;

    @media (max-width: 480px) {
      width: 38vw;
      bottom: 178vw;
      right: 9vw;
    }
  }

  .tree-round-4 {
    width: 25vw;
    position: absolute;
    bottom: 70vw;
    right: -1vw;

    @media (max-width: 480px) {
      width: 50vw;
      position: absolute;
      bottom: 159vw;
      right: -16vw;
    }
  }

  .final-scene {
    position: absolute;
    bottom: 40vw;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1440px) {
      bottom: 10vw;
    }

    .badgers-finish {
      width: 23vw;
      margin-bottom: 70px;

      @media (max-width: 480px) {
        width: 49vw;
      }
    }

    .soil-heading-5 {
      font-size: 60px;
      font-weight: bold;
      padding: 0 15px;

      @media (max-width: 768px) {
        font-size: 35px;
      }
    }

    .logo-monogram {
      z-index: 2;
      margin-top: 150px;
      width: 100px;
      margin-bottom: 100px;

      @media (max-width: 768px) {
        margin-top: 50px;
        width: 40px;
      }

      @media (max-width: 480px) {
        margin-top: 75px;
        width: 50px;
      }
    }
  }
}
