@import "variables";

.intro-scene {
  background-color: $sky;
  font-weight: bold;
  text-align: center;
  position: relative;
  height: 200vh;
  overflow: visible;

  .intro-copy {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    bottom: 60%;
    width: 100%;
    z-index: 2;

    .logo {
      top: 125px;
      width: 220px;

      @media (max-width: 480px) {
        width: 50vw;
      }
    }

    .scroll-indicator {
      text-align: center;
      font-size: 20px;
      position: absolute;
      top: 30vh;
    }
  }

  .insect {
    &.bee {
      width: 30px;
      height: 30px;
    }

    &.butterfly {
      width: 83px;
      height: 54px;
    }
  }

  .insect-path {
    position: absolute;
    width: 50vw;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 2;
    bottom: 42%;
    overflow: visible;

    @media (max-width: 768px) {
      width: 90vw;
    }

    &.insect-1-path, &.insect-3-path {
      transform: translate(-50%, 0) rotate(180deg);
    }

    &.insect-2-path {
      transform: translate(-50%, 0);
    }
  }

  .stalkBottomLayer {
    z-index: 1;
  }

  .stalkTopLayer {
    z-index: 3;
  }

  .stalkTopLayer, .stalkBottomLayer {
    top: 0;
    left: 0;

    @media (max-width: 480px) {
      left: 50%;
      transform: translateX(-50%);
      width: 250vw !important;
    }
  }

  .canopy {
    left: -60px;
    top: -500px;
  }

  .stalk-tops {
    position: absolute;
    width: 100%;
    bottom: 0;

    img {
      position: absolute;
      width: 100%;

      &.stalk-layer {
        top: 1px;
      }
    }
  }

  .soil-badgers {
    z-index: 4;
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;

    .badger-left {
      position: absolute;
      bottom: $spacingRatio * -1vw;
      left: 7vw;
      width: 7vw;

      @media (max-width: 480px) {
        display: none;
      }
    }

    .badger-middle {
      position: absolute;
      bottom: $spacingRatio * 6vw;
      left: 18vw;
      width: 14vw;

      @media (max-width: 480px) {
        position: absolute;
        bottom: 12vw;
        width: 28vw;
        left: -14vw;
      }
    }

    .badger-right {
      position: absolute;
      bottom: $spacingRatio * 11vw;
      width: 25vw;
      right: 37vw;

      @media (max-width: 480px) {
        position: absolute;
        bottom: 18.4vw;
        width: 43vw;
        right: 24vw;
      }
    }

    .flung-dirt-left {
      position: absolute;
      width: 9vw;
      height: 9vw;
      bottom: $spacingRatio * 2vw;
      left: 16vw;

      @media (max-width: 480px) {
        left: -10vw;
      }

      #l-dirt-1, #l-dirt-2, #l-dirt-3 {
        position: absolute;
        left: 0;
      }


    }

    .flung-dirt-right {
      position: absolute;
      width: 12vw;
      height: 12vw;
      bottom: 0;
      right: 17vw;

      #r-dirt-1, #r-dirt-2, #r-dirt-3 {
        position: absolute;
        left: 0;
      }
    }
  }


  .intro-heading {
    position: absolute;
    width: 60vw;
    left: 50%;
    transform: translate(-50%, 0);
    line-height: 1.25em;
    font-size: 40px;
    bottom: 80%;
    z-index: 2;
    padding-top: 3em;

    @media (max-width: 480px) {
      width: 100%;
      max-width: 100%;
      font-size: 32px;
    }


  }

  .intro-badgers {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 3;
    left: 0;

    .badger-left {
      width: 7vw;
      position: absolute;
      bottom: 19vw;
      left: 40.5vw;

      @media (max-width: 480px) {
        width: 21vw;
        position: absolute;
        bottom: 33vw;
        left: 25.5vw;
      }
    }

    .badger-middle {
      width: 14vw;
      position: absolute;
      bottom: 16vw;
      left: 45vw;

      @media (max-width: 480px) {
        width: 21vw;
        position: absolute;
        bottom: 50vw;
        left: 42vw;
      }
    }

    .badger-right {
      width: 6.5vw;
      position: absolute;
      bottom: 20vw;
      left: 51.5vw;

      @media (max-width: 480px) {
        width: 12.5vw;
        position: absolute;
        bottom: 48vw;
        left: 57.5vw;
      }
    }

    .badger-left, .badger-middle, .badger-right {
      transform-origin: right bottom;

      img {
        width: 100%;
      }
    }
  }


  .intro-background-lines {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    background-image: url('../img/sky-lines.png');
    background-size: 110%;
    background-position: 50% 0;
    z-index: 0;
    opacity: 0.8;
    background-repeat: no-repeat;
  }

}
